var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Application Configuration ")]),_c('v-card-text',[_c('v-treeview',{attrs:{"items":_vm.hierarchyItems},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.father_id == '')?_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")]):(item.children.length > 0)?_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")]):(
                item.children.length == 0 &&
                  item.external_link != undefined &&
                  item.external_link != '' &&
                  item.father_id != ''
              )?_c('v-icon',{attrs:{"title":item.external_link}},[_vm._v(" mdi-file-link ")]):(
                item.children.length == 0 &&
                  item.path_s3_bucket != undefined &&
                  item.path_s3_bucket != '' &&
                  item.father_id != ''
              )?_c('v-icon',[_vm._v(" mdi-file-pdf ")]):_c('v-icon',[_vm._v(" mdi-file ")])]}},{key:"label",fn:function(ref){
              var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',[_vm._v(" "+_vm._s(item.node_name)+" ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('span',[_vm._v(" "+_vm._s(item.node_description)+" ")])])],1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('service-treeview',{attrs:{"route-type":_vm.routeType,"is-translating":_vm.services.isTranslating,"is-creating-profiling":true,"selected-language":_vm.selectedLanguageText}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }